body {
  scroll-behavior: smooth !important;
}

button:focus {
  outline: none;
}
.pulse-anime1 {
  animation: pulse1 4s cubic-bezier(0.4, 0, 0.6, 1) infinite;
}

@keyframes pulse1 {
  0%,
  100% {
    opacity: 1;
  }
  50% {
    opacity: 0.5;
  }
}
.pulse-anime2 {
  animation: pulse2 6s cubic-bezier(0.4, 0, 0.6, 1) infinite;
}

@keyframes pulse2 {
  0%,
  100% {
    opacity: 1;
  }
  50% {
    opacity: 0.5;
  }
}
.pulse-anime3 {
  animation: pulse3 7s cubic-bezier(0.4, 0, 0.6, 1) infinite;
}

@keyframes pulse3 {
  0%,
  100% {
    opacity: 1;
  }
  50% {
    opacity: 0.5;
  }
}

.card-hover {
  top: 88%;
  opacity: 1 !important;
  -webkit-transition: all 0.3s ease-out;
  -moz-transition: all 0.3s ease-out;
  -o-transition: all 0.3s ease-out;
}

.card-container:hover .card-hover {
  top: 0%;
  opacity: 1;
}

.pricing-bg {
  height: 110% !important;
  width: 110%;
  border-radius: 200px;
}

/* loading bar */
.bar {
  height: 2px;
  background: #dbeafe;
  overflow: hidden;
}
.bar div:before {
  content: "";
  position: absolute;
  top: 0px;
  left: 0px;
  bottom: 0px;
  background: #2b6ceb;
  animation: box-1 2100ms cubic-bezier(0.65, 0.81, 0.73, 0.4) infinite;
}
.bar div:after {
  content: "";
  position: absolute;
  top: 0px;
  left: 0px;
  bottom: 0px;
  background: #2b6ceb;
  animation: box-2 2100ms cubic-bezier(0.16, 0.84, 0.44, 1) infinite;
  animation-delay: 1150ms;
}
@keyframes box-1 {
  0% {
    left: -35%;
    right: 100%;
  }
  60%,
  100% {
    left: 100%;
    right: -90%;
  }
}
@keyframes box-2 {
  0% {
    left: -200%;
    right: 100%;
  }
  60%,
  100% {
    left: 107%;
    right: -8%;
  }
}
